import { Heading, assetUrl } from "@kaizen/components"
import React from "react"
import classNames from "classnames"

import styles from "./Head.module.scss"

interface Props {
  central?: boolean
}

export const Head: React.FC<Props> = ({ central = false }) => {
  return (
    <div className={classNames(styles.head, central ? styles.central : {})}>
      <title>Sign in to Culture Amp</title>
      <div className={styles.logo}>
        <a href="https://www.cultureamp.com/" aria-label="Culture Amp home">
          <img
            src={assetUrl("brand/logo-horizontal-default.svg")}
            alt="Culture Amp"
          />
        </a>
      </div>
      <div className={styles.welcome}>
        <Heading variant="display-0" tag="h1">
          Let&rsquo;s create a better
          <br />
          world of work
        </Heading>
      </div>
    </div>
  )
}
