import { Text } from "@kaizen/components"
import { determineEnvironment } from "@cultureamp/frontend-env"
import { getSignInUrl } from "../../../utils"
import React, { useEffect, useState } from "react"
import classNames from "classnames"
import styles from "./Foot.module.scss"

interface Props {
  central?: boolean
}

interface AlternativeHost {
  title: string
  url: string
}

export const Foot: React.FC<Props> = ({ central = false }) => {
  const [alternativeHost, setAlternativeHost] = useState<AlternativeHost>()
  useEffect(() => {
    const env = determineEnvironment()
    const isEURegion = env.kind == "production-eu"
    const host = window.location.hostname
    const protocol = window.location.protocol
    const url = getSignInUrl(protocol, host, isEURegion)
    const title = isEURegion ? "Global sign-in" : "EU sign-in"

    if (env.realm === "production") {
      setAlternativeHost({
        title,
        url,
      })
    }
  }, [])

  return (
    <div className={classNames(styles.foot, central ? styles.central : {})}>
      <Text variant="body">
        <a
          className={styles.link}
          href="https://www.cultureamp.com/privacy-policy"
          target="_blank"
        >
          Privacy
        </a>{" "}
        •{" "}
        <a
          className={styles.link}
          href="https://www.cultureamp.com/terms"
          target="_blank"
        >
          Terms
        </a>
        {alternativeHost && (
          <>
            {" "}
            •{" "}
            <a className={styles.link} href={alternativeHost.url}>
              {alternativeHost.title}
            </a>
          </>
        )}
      </Text>
    </div>
  )
}
